import React from 'react';
import classnames from 'classnames';

import { InlineCtaComponentFragment } from '../../../../graphql.schema';
import StrapiButton from '../../StrapiLink/StrapiButton';

import * as styles from './index.module.scss';

export const StrapiInlineCtaComponent: React.FC<InlineCtaComponentFragment> = (props) => {
  return (
    <div className={classnames(styles.root, { 'has-bg-brand-a': props.style === 'dark' })}>
      <div className={styles.textContainer}>
        <h3 className={classnames('title is-3 mb-0', styles.title)}>{props.title}</h3>
        <div className="columns">
          <div className="column is-8-desktop">
            <p className="text-1">{props.description}</p>
          </div>
        </div>
      </div>
      {props.link ? <StrapiButton type="primary" {...props.link}></StrapiButton> : null}
    </div>
  );
};
