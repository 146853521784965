import React from 'react';
import { FormattedDate } from 'react-intl';
import { graphql, PageProps } from 'gatsby';
import classnames from 'classnames';

import Breadcrumb from '../../components/Breadcrumb';
import Tag from '../../components/Tag';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import { StrapiInlineCtaComponent } from '../../components/StrapiComponents/StrapiInlineCtaComponent';
import { RichTextField } from '../../components/RichTextField/RichTextField';
import StrapiButton from '../../components/StrapiLink/StrapiButton';
import { SingleCareerPostQuery, InlineCtaComponentFragment } from '../../../graphql.schema';
import SEO from '../../components/SEO/SEO';
import { GlobalPageContext } from '../../types';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './careers-post.module.scss';
import { get } from 'lodash';

type PageContextType = GlobalPageContext & {
  id: string;
  location: string;
};

const CareersPage: React.FC<PageProps<SingleCareerPostQuery, PageContextType>> = ({ data, pageContext }) => {
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });
  const { careerPost: entry } = data.strapi;

  const showFooterCta = entry?.pageSettings?.showFooterCta ?? false;
  const footerCta = entry?.pageSettings?.customFooterCta?.id
    ? entry?.pageSettings?.customFooterCta
    : websiteLocale.footerCta;

  if (!websiteLocale || !entry) return <p>Not found</p>;

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={entry.seo?.metaTitle ?? entry.pageSettings?.title ?? entry.title ?? ''}
        description={
          entry.seo?.metaDescription ?? entry.pageSettings?.metaDescription ?? entry.content.substring(1, 100)
        }
        image={entry.seo?.metaImage?.url}
        avoidIndexing={entry.pageSettings?.avoidIndexing ?? false}
        canonicalURL={entry.seo?.canonicalURL}
        keywords={entry.seo?.keywords}
        metaSocial={entry.seo?.metaSocial}
        structuredData={entry.seo?.structuredData}
      />
      <div className="container">
        <Breadcrumb pageContext={pageContext} lastCrumb={entry.title}></Breadcrumb>
        <div className={styles.root}>
          <h1 className="title color-a has-text-centered">{entry.title}</h1>
          <div className="is-flex is-justify-content-center">
            <Tag isClickable={false} name={entry.jobType?.name ?? ''} key={`${entry.jobType?.name}`} />
            <Tag isClickable={false} name={entry.city?.name ?? ''} key={`${entry.city?.name}`} />
          </div>
          <time className={classnames('text-2 black-3', styles.date)}>
            <FormattedDate value={entry.publishedAt} year="numeric" month="short" day="2-digit" />
          </time>
          <RichTextField className="description pt-10 pb-5 black-2" content={entry.content} />
          <div className={classnames('is-flex is-flex-direction-row is-justify-content-center', styles.linkWrapper)}>
            {entry.link ? <StrapiButton {...entry.link} /> : null}
          </div>
        </div>
      </div>
      {entry.callToAction && <StrapiInlineCtaComponent {...(entry.callToAction as InlineCtaComponentFragment)} />}
      {showFooterCta && footerCta && <StrapiFooterCtaComponent {...footerCta} />}
    </LayoutWrapper>
  );
};

export const query = graphql`
query SingleCareerPost($id: ID!) {
  strapi {
    careerPost(id: $id) {
      data {
        id
        attributes {
          title
          slug
          content
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          pageSettings: page_settings {
            ...PageSettings
          }
          jobType: job_type {
            ...NamedJobEntityResponse
          }
          city {
            ...NamedCityEntityResponse
          }
          link {
            ...LinkComponent
          }
          callToAction: call_to_action {
            ...InlineCTAComponent
          }
          publishedAt
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(CareersPage));
