import React from 'react';
import classnames from 'classnames';

import * as styles from './index.module.scss';

type Props = {
  name: string;
  isActive?: boolean;
  isClickable: boolean;
  onClick?: () => void;
};

const Tag: React.FC<Props> = ({ name, isActive, onClick, isClickable }) => (
  <span
    onClick={onClick}
    className={classnames(styles.customTag, isActive ? styles.active : '', isClickable ? styles.isClickable : '')}
  >
    {name}
  </span>
);

export default Tag;
