import React, {useContext} from 'react';
import classnames from 'classnames';
import { ArrowLeft, ArrowRight, ChevronRight, ChevronLeft } from 'react-feather';

import StrapiLinkUnstyled from './StrapiLinkUnstyled';
import { LinkComponentFragment, Maybe } from '../../../graphql.schema';
import { PageContext } from '../PageContext';
import { v4tov3base} from '../../lib/mapV4toV3';

import * as styles from './StrapiLink.module.scss';

export function isExternalURL(url: string) {
  try {
    return new URL(url).host !== location.host;
  } catch (e) {
    return false;
  }
}

export type StrapiLinkProps = Omit<LinkComponentFragment, 'id' | 'openInNewWindow'> & {
  id?: string;
  openInNewWindow?: Maybe<boolean>;
  icon?: 'Arrow' | 'Chevron';
  className?: string;
  hideIcon?: boolean;
};

const StrapiLink: React.FC<StrapiLinkProps> = ({ hideIcon, icon = 'Chevron', title, className, ...rest }) => {
  const { websiteLocale } = useContext(PageContext);
  const { hasRightToLeftLanguage } = v4tov3base({...websiteLocale.attributes});
  return (
    <StrapiLinkUnstyled className={classnames(styles.link, className)} {...rest}>
      <span>{title}</span>
      {hasRightToLeftLanguage ? (!hideIcon ? icon === 'Chevron' ? <ChevronLeft size={16} className={styles.chevron} /> : <ArrowLeft size={16} className={styles.chevron} /> : null) : (!hideIcon ? icon === 'Chevron' ? <ChevronRight size={16} className={styles.chevron} /> : <ArrowRight size={16} className={styles.chevron} /> : null)}
    </StrapiLinkUnstyled>
  );
};

export default StrapiLink;
